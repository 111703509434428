<template>
  <div>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="3">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back" v-show="!serviceId">
            新增服务
          </i>
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back" v-show="serviceId">
            编辑服务
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <!-- 表单 -->
      <el-main style="margin-top: 20px">
        <el-form ref="form" :model="form" label-width="120px" :rules="serviceRules" >
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="服务名称" prop="service_name">
                <el-input
                  v-model="form.service_name"
                  show-word-limit
                  maxlength="15"
                  placeholder="请输入服务名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="关键字描述" prop="service_keyword">
                <el-input
                  v-model="form.service_keyword"
                  show-word-limit
                  maxlength="12"
                  placeholder="请输入服务关键字描述"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="封面图" prop="service_logo" style="text-align: left">
                <div class="previewUpload">
                  <div v-show="form.service_logo.length>0" class="previewItem" v-for="(item,index) in form.service_logo" :key="index">
                    <img :src="item.img_path" alt="" @click="openUploadDialog((1-form.service_logo.length),'cover')">
                    <i class="el-icon-close delIcon" @click="delCoverItem(index)"></i>
                  </div>
                  <div class="previewItems" v-if="form.service_logo.length==0" @click="openUploadDialog((1-form.service_logo.length),'cover')"><i class="el-icon-plus previewadd"></i></div>
                </div>
                <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item prop="service_lunbo" label="轮播图" style="text-align: left">
                <div class="previewUpload">
                  <div v-show="form.service_lunbo.length>0" class="previewItem" v-for="(item,index) in form.service_lunbo" :key="index">
                    <img :src="item.img_path" alt="" class="previewadd">
                    <i class="el-icon-close delIcon" @click="delCarouselItem(index)"></i>
                  </div>
                  <div class="previewItems" @click="openUploadDialog((9-form.service_lunbo.length),'carousel')"><i class="el-icon-plus previewadd"></i></div>
                </div>
                <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
            </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="服务价格(元)" prop="service_price">
            		<el-input v-model="form.service_price" placeholder="请输入服务价格"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="服务原价(元)" prop="real_price">
                <el-input v-model="form.real_price" placeholder="请输入服务原价"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="虚拟销售量" prop="sales_num">
                <el-input v-model.number="form.sales_num" placeholder="请输入虚拟销售量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-tooltip
                class="item"
                effect="dark"
                content="虚拟销售量=虚拟+实际销售量"
                placement="right"
              >
                <i class="el-icon-question" style="font-size: 18px"></i>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="服务时长(分)" prop="service_time">
                <el-input
                  v-model.number="form.service_time"
                  placeholder="请输入服务时长"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-tooltip
                class="item"
                effect="dark"
                content="一次服务的时间段,一般为60分钟"
                placement="right"
              >
                <i class="el-icon-question" style="font-size: 18px"></i>
              </el-tooltip>
            </el-col>
          </el-row>
          <!--<el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="返佣金额(%)" prop="service_fybl">
                <el-input
                  v-model.number="form.service_fybl"
                  oninput="if(value>100)value=100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-tooltip
                class="item"
                effect="dark"
                content="实际支付金额的百分比"
                placement="right"
              >
                <i class="el-icon-question" style="font-size: 18px"></i>
              </el-tooltip>
            </el-col>
          </el-row>-->
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="排序值" prop="service_sort">
                <el-input v-model.number="form.service_sort"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-tooltip
                class="item"
                effect="dark"
                content="值越小,排序越靠前"
                placement="right"
              >
                <i class="el-icon-question" style="font-size: 18px"></i>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="13">
              <el-form-item label="项目介绍" prop="service_xmjs">
                <el-input
                  v-model="form.service_xmjs"
                  show-word-limit
                  maxlength="1000"
                  :rows="10"
                  type="textarea"
                  placeholder="请输入项目介绍"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="13">
              <el-form-item label="禁忌说明" prop="service_jjsm">
                <el-input
                  v-model="form.service_jjsm"
                  show-word-limit
                  maxlength="1000"
                  :rows="10"
                  type="textarea"
                  placeholder="请输入禁忌说明"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="13">
              <el-form-item label="下单须知" prop="service_xdxz">
                <el-input
                  v-model="form.service_xdxz"
                  show-word-limit
                  maxlength="1000"
                  :rows="10"
                  type="textarea"
                  placeholder="请输入下单须知"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="5">
              <el-form-item label="关联技师">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="chooseTechnician"
                  >选择技师</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="" prop="relation">
                <el-table 
                  :data="form.relation"
                  style="width: 100%"
                  :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                >
                  <el-table-column prop="jishi_id" label="技师ID"> </el-table-column>
                  <el-table-column label="技师头像">
                    <template slot-scope="scope">
                      <div>
                        <el-avatar
                          shape="square"
                          size="large"
                          :src="scope.row.gzxxz_img"
                        ></el-avatar>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="jishi_name" label="技师姓名"></el-table-column>
                  <el-table-column prop="phone" label="手机号"></el-table-column>
                  <el-table-column label="操作" width="100px">
										<template slot-scope="scope">
											<div>
												<el-button type="danger" plain size="mini" @click="remove(scope.$index)">删除</el-button>
											</div>
										</template>
									</el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10" style="text-align:left">
              <el-form-item>
                <el-button type="primary" @click="edit">提交</el-button>
                <el-button type="default" @click="back">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <!-- 对话框 -->
      <el-dialog title="关联技师" :visible.sync="dialogVisible" width="60%">
        <el-row :gutter="10" style="margin-bottom: 20px">
          <el-col :span="10">
            <el-input
              placeholder="请输入技师姓名/手机号"
              v-model="technicianText"
              clearable
              @clear="getTechnician"
            >
             <!-- <el-button
                slot="append"
                icon="el-icon-search"
                @click="getTechnician"
              ></el-button> -->
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchTechnician"
              >搜索</el-button
            >
          </el-col>
        </el-row>
        <!-- 表格 -->
        <el-table ref="xyfwTable"
          @select="select"
          @select-all="selectAll"
          :data="technicianTable"
          style="width: 100%"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="jishi_id" label="技师ID"></el-table-column>
          <el-table-column label="技师头像">
            <template slot-scope="scope">
              <div>
                <el-avatar
                  shape="square"
                  size="large"
                  :src="scope.row.gzxxz_img"
                ></el-avatar>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="jishi_name" label="技师姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
        <el-button type="default" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="relation">确认</el-button>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
let that;
let timer=null;
import Pagination from '@/components/Pagination/Pagination.vue'
import Upload from '@/components/Upload/Upload.vue'
export default {
  components:{
    Pagination,Upload
  },
  data() {
    // 验证手机
    var checkRelation = (rule, value, cb) => {
      if (this.form.relation.length!==0) {
        return cb();
      } else {
        cb(new Error("请选择关联技师"));
      }
    };
    return {
      serviceId:this.$route.query.id,
      form: {
        service_name: "",
        service_keyword: "",
        service_logo: [],
        service_lunbo: [],
        service_price: null,
        real_price: null,
        sales_num: null,
        service_time: null,
//      service_fybl: 0,
        service_sort: 999,
        service_xmjs: "",
        service_jjsm: "",
        service_xdxz: "",
        relation: [],
      },
      pictureType:'',
      serviceRules: {
        service_name: [{ required: true, message: "服务名称不能为空", trigger: "blur" },],
        service_keyword: [{ required: true, message: "关键字描述不能为空", trigger: "blur" },],
        service_logo: [{ required: true, message: "请上传图片", trigger: "blur" },],
        service_lunbo: [{ required: true, message: "请上传图片", trigger: "blur" },],
        service_price: [
          { required: true, message: "服务价格不能为空", trigger: "blur" },
          { pattern: /^-?\d+\.?\d{0,2}$/, message: "请输入合法的金额数字，最多两位小数", trigger: "blur" },
        ],
        real_price: [
          { required: true, message: "服务原价不能为空", trigger: "blur" },
          { pattern: /^-?\d+\.?\d{0,2}$/, message: "请输入合法的金额数字，最多两位小数" },
        ],
        sales_num: [
          { required: true, message: "虚拟销售量不能为空", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        service_time: [
          { required: true, message: "服务时长不能为空", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
//      service_fybl: [
//        { required: true, message: "请输入返佣金额", trigger: "blur" },
//        { type: "number", message: "请输入数字" },
//      ],
        service_sort: [
          { required: true, message: "请输入排序值", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        service_xmjs: [
          { required: true, message: "请输入项目介绍", trigger: "blur" },
        ],
        service_jjsm: [
          { required: true, message: "请输入禁忌说明", trigger: "blur" },
        ],
        service_xdxz: [
          { required: true, message: "请输入下单须知", trigger: "blur" },
        ],
        relation:[
          { validator: checkRelation, trigger: "blur" },
        ]
      },
      dialogVisible: false,
      checked: false,
      technicianTable: [],
      technicianText: "",
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total: 0,
      selectdata:[],
      previewVisible:false,
      previewImageUrl:'',
      uploadVisible:false,
      chooseImgCount:0,
      chooseList:[],
    };
  },
  created() {
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			this.getJishiList();
			if(this.$route.query.id){
				this.getServiceDetail();
			}
		}
  },
  mounted() {},
  methods: {
  	getServiceDetail(){
  		var url = 'service/service_detail';
			let params = {
				service_id: this.$route.query.id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					console.log(res.detail);
					that.form = res.detail;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	getJishiList() {
			var url = 'jishi/jishi_list';
			let params = {
				jishi_name: this.technicianText,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.total = res.total;
					that.technicianTable = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
		},
    receiveRes(chooseList,pictureType){
      if(pictureType==='cover'){
        this.form.service_logo = this.form.service_logo.concat(chooseList);
      }else if(pictureType=='carousel'){
        this.form.service_lunbo = this.form.service_lunbo.concat(chooseList)
      }
      this.uploadVisible = false;
    },
    closeDelDialog(){
      this.uploadVisible=false
    },
    back() {
      this.$router.go(-1);
    },
    openUploadDialog(count,pictureType){
      this.pictureType=pictureType
      this.chooseImgCount=count
      this.uploadVisible=true
    },
    delCoverItem(index){
      this.form.service_logo.splice(index,1)
    },
    delCarouselItem(index){
      this.form.service_lunbo.splice(index,1)
    },
    getTechnician() {
    	this.getJishiList();
    },
    searchTechnician() {
    	this.getJishiList();
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = newSize;
      this.getJishiList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getJishiList();
    },
    chooseTechnician() {
      this.dialogVisible = true;
    },
    remove(index){
    	this.form.relation.splice(index, 1)
    },
    //关联技师
    relation() {
    	this.dialogVisible = false;
    	let newarr = [...this.form.relation, ...this.selectdata]
			let map = new Map()
			this.form.relation = newarr.filter(item => !map.has(item.jishi_id) && map.set(item.jishi_id, 1))
			this.selectdata = [];
			this.$refs.xyfwTable.clearSelection()
    },
    select(selection, row) {
      this.selectdata = selection;
    },
    selectAll(selection){
      this.selectdata = selection;
    },
    //提交
    edit(){
      this.$refs.form.validate(valid=>{
        if(!valid) return;
        else{
        	if(timer != null){clearTimeout(timer);}
        	timer = setTimeout(() => {
			    	that.form.service_logo = JSON.stringify(that.form.service_logo);
		      	that.form.service_lunbo = JSON.stringify(that.form.service_lunbo);
		      	that.form.jishi_ids = JSON.stringify(that.form.relation);
	        	var url = 'service/addOrUpdate';
		        let params = that.form;
		        that.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			that.$refs.form.resetFields();
	        			that.$message.success("操作成功");
	        			that.$router.push({path: '/service_add'});
	        		}else{
	        			that.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            that.$message.error('网络错误');
		        });
			    }, 1000);
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./Add.scss";
</style>